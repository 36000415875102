/********************
IMPORTS
********************/
@import "assets/scss/mixins.scss";

:root {
  /* Primary Color */
  --primary-color-lite: #fc4349;
  --primary-color-dark: #e7131a; // hover (happy red)
  --primary-color-darker: #e7131a; // main color
  --primary-color-darkest: #cf1117; // main color

  /* Secondary Color */
  --secondary-color-lite: #0d1e38;
  --secondary-color-med: #041022;
  --secondary-color-dark: #010914;

  /* Greys */
  --grey-lite: #f5f5f5;
  --grey: #d6d7da;
  --grey-med: #999da3;
  --grey-dark: #6c717a;
  --grey-darker: #515151; // not set
  --grey-darkest: #2e2e2e; // not set

  /* Other */
  --color-success: #308406;
  --color-info: #1960d0;
  --color-error: #f26900;

  --font-black: #041022;
  --font-white: #ffffff;
}

/* Font Family Import */
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");
@font-face {
  font-family: "VroomSans";
  font-display: block;
  src: url("/assets/fonts/vroom/VroomSans/VroomSans-ExtraBoldItalic.woff2") format("woff2"), url("/assets/fonts/vroom/VroomSans/VroomSans-ExtraBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "CalibreWeb-Bold";
  font-display: block;
  src: url("/assets/fonts/vroom/CalibreWeb/CalibreWeb-Bold.woff2") format("woff2"), url("/assets/fonts/vroom/CalibreWeb/CalibreWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: "CalibreWeb-Regular";
  font-display: block;
  src: url("/assets/fonts/vroom/CalibreWeb/CalibreWeb-Regular.woff2") format("woff2"), url("/assets/fonts/vroom/CalibreWeb/CalibreWeb-Regular.woff") format("woff");
}
@font-face {
  font-family: "CalibreWeb-Semibold";
  font-display: block;
  src: url("/assets/fonts/vroom/CalibreWeb/CalibreWeb-Semibold.woff2") format("woff2"), url("/assets/fonts/vroom/CalibreWeb/CalibreWeb-Semibold.woff") format("woff");
}

:root {
  /* Font Families */
  --font-family: "CalibreWeb-Regular", sans-serif;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;

  /* Font Sizes */
  --h1-size: 62px;
  --h2-size: 42px;
  --h3-size: 24px;
  --h4-size: 20px;
  --h5-size: 16px;
  --f-body-size: 18px;
  --f-cap-head-size: 16px;
  --f-cap-sub-size: 14px;
  --f-btn-size: 15px;

  // xs = For devices smaller than an iPad
  --h1-size-xs: 42px;
  --h2-size-xs: 32px;
  --h3-size-xs: 24px;
  --h4-size-xs: 20px;
  --h5-size-xs: 20px;

  // xxs = For devices with very small screens, like an iPhone 5
  --h1-size-xxs: 32px;
  --h2-size-xxs: 32px;
  --h3-size-xxs: 18px;
  --h4-size-xxs: 16px;
  --h5-size-xxs: 16px;
  --f-body-size-xxs: 16px;
  --f-cap-head-size-xxs: 12px;
  --f-cap-sub-size-xxs: 12px;
  --f-btn-size-xxs: 12px;

  /* Line Heights */
  --h1-lineheight: 64px;
  --h2-lineheight: 48px;
  --h3-lineheight: 32px;
  --h4-lineheight: 24px;
  --h5-lineheight: 24px;

  /* Line Heights xs devices */
  --h1-lineheight-xs: 48px;
  --h2-lineheight-xs: 40px;
  --h3-lineheight-xs: 32px;
  --h4-lineheight-xs: 24px;
  --h5-lineheight-xs: 24px;

  /* Line Heights xs devices */
  --h1-lineheight-xxs: 40px;
  --h2-lineheight-xxs: 40px;
  --h3-lineheight-xxs: 32px;
  --h4-lineheight-xxs: 24px;
  --h5-lineheight-xxs: 24px;

  /* Font Weights */
  --h1-weight: var(--font-bold);
  --h2-weight: var(--font-bold);
  --h3-weight: var(--font-medium);
  --h4-weight: var(--font-medium);
  --h5-weight: var(--font-medium);
  --f-body-weight: var(--font-regular);
  --f-cap-head-weight: var(--font-medium);
  --f-cap-sub-weight: var(--font-regular);
  --f-btn-weight: var(--font-medium);

  /* Font Colors */
  --h1-color: var(--primary-color-darker);
  --h2-color: var(--primary-color-darker);
  --h3-color: var(--primary-color-darker);
  --h4-color: var(--primary-color-darker);
  --h5-color: var(--primary-color-darker);
  --f-body-color: var(--grey-darker);
  --f-cap-head-color: var(--grey-darker);
  --f-cap-sub-color: var(--grey-darker);
  --f-btn-color-lite: white;
  --f-btn-color-dark: var(--grey-darker);

  /* Line Spacing */
  --f-body-space: 24px;
  --f-cap-head-space: 20px;
  --f-cap-sub-space: 16px;

  // xxs - For devices with really small screens, like an iPhone 5
  --f-body-space-xxs: 24px;
  --f-cap-head-space-xxs: 16px;
  --f-cap-sub-space-xxs: 12px;

  :focus-visible {
    outline: 2px solid var(--grey-darker) !important;
  }

  select:focus-visible {
    outline: 2px solid var(--grey-darker) !important;
    padding-right:3px;
  }

  body {
    color: #041022;
    font-family: "CalibreWeb-Regular", sans-serif;
    font-size: 16px;
  }

  /* Global Header Font Styles */
  h1 {
    color: var(--font-black);
    font-family: "VroomSans", sans-serif;
    font-size: var(--h1-size) !important;
    font-weight: var(--h1-weight) !important;
    line-height: var(--h1-lineheight) !important;
    // color:          var(--h1-color);
    @include breakpoint(xs) {
      font-size: var(--h1-size-xs) !important;
      line-height: var(--h1-lineheight-xs) !important;
    }
    @include breakpoint(xxs) {
      font-size: var(--h1-size-xxs) !important;
      line-height: var(--h1-lineheight-xxs) !important;
    }
  }
  h2 {
    color: var(--font-black);
    font-family: "VroomSans", sans-serif;
    font-size: var(--h2-size) !important;
    font-weight: var(--h2-weight) !important;
    line-height: var(--h2-lineheight) !important;
    color: inherit;
    @include breakpoint(xs) {
      font-size: var(--h2-size-xs) !important;
      line-height: var(--h2-lineheight-xs) !important;
    }
    @include breakpoint(xs) {
      font-size: var(--h2-size-xxs) !important;
      line-height: var(--h2-lineheight-xxs) !important;
    }
  }
  h3 {
    color: var(--font-black);
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: var(--h3-size) !important;
    font-weight: var(--h3-weight) !important;
    line-height: var(--h3-lineheight) !important;
    color: inherit;
    @include breakpoint(xs) {
      font-size: var(--h3-size-xs) !important;
      line-height: var(--h3-lineheight-xs) !important;
    }
    @include breakpoint(xs) {
      font-size: var(--h3-size-xxs) !important;
      line-height: var(--h3-lineheight-xxs) !important;
    }

  }
  h4 {
    color: var(--font-black);
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: var(--h4-size) !important;
    font-weight: var(--h4-weight) !important;
    line-height: var(--h4-lineheight) !important;
    color: inherit;
    @include breakpoint(xs) {
      font-size: var(--h4-size-xs) !important;
      line-height: var(--h3-lineheight-xs) !important;
    }
    @include breakpoint(xxs) {
      font-size: var(--h4-size-xxs) !important;
      line-height: var(--h3-lineheight-xxs) !important;
    }
  }
  h5 {
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: var(--h5-size) !important;
    font-weight: var(--h5-weight) !important;
    line-height: var(--h5-lineheight) !important;
    color: inherit;
    @include breakpoint(xs) {
      font-size: var(--h5-size-xs) !important;
      line-height: var(--h5-lineheight-xs) !important;
    }
    @include breakpoint(xxs) {
      font-size: var(--h5-size-xxs) !important;
      line-height: var(--h5-lineheight-xxs) !important;
    }
  }

  a {
    color: var(--color-info);
    &:hover {
      color: #08419c;
    }
  }

  /* Global Body Font Styles */

  .bs-tooltip-top .arrow:before {
    border-top-color: var(--grey-lite) !important;
  }
  .bs-tooltip-right .arrow:before {
    border-right-color: var(--grey-lite) !important;
  }
  .bs-tooltip-bottom .arrow:before {
    border-bottom-color: var(--grey-lite) !important;
  }
  .bs-tooltip-left .arrow::before {
    border-left-color: var(--grey-lite) !important;
  }

  mdb-tooltip-container {
    .tooltip-inner {
      background: var(--grey-lite) !important;
      max-width: 350px !important;
      min-width: 300px !important;
      color: var(--grey-dark) !important;
      padding: 0.25rem 1rem;
    }
  }

  p {
    font-size: var(--f-body-size) !important;
    font-weight: var(--f-body-weight) !important;
    // color:          var(--f-body-color)!important;
    line-height: var(--f-body-space) !important;
    letter-spacing: normal;
    @include breakpoint(xs) {
      font-size: var(--f-body-size-xxs) !important;
      line-height: var(--f-body-space-xxs) !important;
    }
  }
  .caption-header {
    font-size: var(--f-cap-head-size) !important;
    font-weight: var(--f-cap-head-weight) !important;
    color: var(--f-cap-head-color) !important;
    line-height: var(--fi-cap-head-space) !important;
    @include breakpoint(xs) {
      font-size: var(--f-cap-head-size-xxs) !important;
      line-height: var(--fi-cap-head-space-xxs) !important;
    }
  }
  .caption-subheader {
    font-size: var(--f-cap-sub-size) !important;
    font-weight: var(--f-cap-sub-weight) !important;
    color: var(--f-cap-sub-color) !important;
    line-height: var(--fi-cap-sub-space) !important;
    @include breakpoint(xs) {
      font-size: var(--f-cap-sub-size-xxs) !important;
      line-height: var(--fi-cap-sub-space-xxs) !important;
    }
  }
  .primary-btn {
    border-radius: 0px !important;
    box-shadow: none !important;
  }
  .btn.secondary-btn {
    border: 2px solid var(--primary-color-dark) !important;
    background-color: transparent;
    &:hover {
      background-color: var(--primary-color-dark);
    }
  }
  .plans-title-section {
    margin-top: -15rem;
    color: white;
    text-align: center;
    display: block;
    h1 {
      // color: white;
    }
    @include breakpoint(xs) {
      margin-top: -17rem;
    }
    @include breakpoint(xxs) {
      margin-top: -17rem;
    }
    padding-bottom: 3em;
  }

  .alert-warning {
    color: var(--color-error);
    border-color: var(--color-error);
    font-family: "CalibreWeb-Semibold";
    font-size: 13px;
  }

  .alert-info {
    color: var(--color-info);
    border-color: var(--color-info);
    font-family: "CalibreWeb-Semibold";
    font-size: 132px;
  }

  .error-message {
    font-family: "CalibreWeb-Bold", sans-serif;
    color: var(--color-error) !important;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: none;
  }
  input.form-control.validate-error.ng-invalid.ng-touched {
    border-bottom: 1px solid var(--color-error);
  }

  mdb-tabset ul.md-tabs.tabs-3 {
    li {
      height: 48px;
      line-height: 48px;
      a {
        padding-top: 10px;
        text-transform: uppercase;

        p {
          letter-spacing: 1px !important;
        }
      }
    }

    .nav-item p {
      padding: 0 !important;
    }
  }

  mdb-tabset .tab-pane i.fa-check {
    color: var(--primary-color-darker);
  }

  .btn {
    text-transform: uppercase;
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
  }

  // footer {
  //   background: black;
  //   color: white;
  //   .footer-group-contact-us {
  //     text-align: center;
  //     .give_us_a_call {
  //       font-size: 1.5rem;
  //       font-weight: bold;
  //     }
  //   }
  //   p {
  //     font-size: 16px !important;
  //     line-height: 20px !important;
  //   }
  //   ul mdb-icon {
  //     color: white;
  //   }
  // }

  footer .footer-copyright {
    background: black;
    color: white;
    p {
      font-size: 11px !important;
      line-height: 21px !important;
    }
    a {
      color: white !important;
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 12px !important;
      width: 100px;
    }
    a:hover {
      text-decoration: none;
    }
 }

  .subfooter {
    .subfooter-item img {
      padding: 0 !important;
      height: 32px !important;
    }
    a:hover {
      color: #e71519;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1rem !important;
    }
  }
  .rgba-black-light,
  .rgba-black-light:after {
    background-color: rgba(0, 0, 0, 0);
  }


   /********************
    FOOTER
    ********************/


  footer > div {
    background-color: black;
    margin-top: 0;
    width: 100%;
  }
  footer {
    .give_us_a_call {
      margin-top: 20px;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .footer-version {
      font-size: 12px;
    }
  }

  footer .footer-group-logo {
    max-width: 100%;
    width: 100%;
    text-align: center;
    flex: none;
    margin-top: 60px;
    .footer-logo {
      padding: 0 !important;
      img {
        width: 200px;
      }
    }
  }
  footer .footer-group-contact-us {
    strong {
      display: none;
    }
    .list-unstyled {
      text-align: center !important;
      color: white;
      i.fas {
        color: white;
      }
    }
  }
  .hours-of-operation {
    color: var(--grey-lite) !important;
  }


// footer {
//   background: #f5f5f5;
// }


  /********************
    LEGAL PAGE
    ********************/

  app-legal-notice .legal,
  app-privacy .legal,
  app-accessibility .legal {
    h1 {
      padding: 10px 0 20px 0;
    }
    h2 {
      padding-top: 40px;
      padding-bottom: 16px;
    }
  }

  /********************
    FAQ SLICE
    ********************/

  .faq-overview {
    margin-top: 10px;
    padding: 30px 0;
    .faq {
      padding: 0 20px;
    }
    .container {
      .faq-header {
        text-align: center;
        margin-bottom: 10px;
      }
      .card-header {
        h5 {
          width: calc(100% - 32px);
        }
      }
      .card-body {
        p {
          color: #626262 !important;
          font-size: 0.9rem !important;
          font-weight: 300 !important;
          line-height: 1.7 !important;
        }
      }
    }
  }

  /********************
    PRODUCT PLAN PAGE
    ********************/

  .commercial-use-section {
    padding-top: 0.50rem;
  }

  .click-text {
    font-family: "CalibreWeb-Bold", sans-serif;
    color: var(--color-info) !important;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
    white-space: nowrap;
    // padding-bottom: 40px;
  }

  .plans-hero-section .tab-container .tab-pane ul li .nav-link:hover {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lowest-term-price h3 {
    color: var(--font-black) !important;
  }

  app-product-plans app-slice-plans-hero {
    h1 {
      color: white;
    }
    mdb-tabset {
      ul li a img {
        // display: none;
      }
    }
    .col-md-3 {
      background-color: var(--grey-lite);
    }

    ul.md-pills li a {
      span {
        text-transform: uppercase;
        font-family: "CalibreWeb-Semibold", sans-serif;
        letter-spacing: 1px;
        font-size: 14px;
        img {
          display: none;
        }
      }
      &.nav-link.active {
        background: white;
        border-radius: 0 !important;
        color: var(--primary-color-darker);
        border-bottom: 1px solid var(--primary-color-darker);
        img {
          float: right;
          display: block;
        }
      }
    }
    .plans-hero-section .tab-container .nav-stacked {
      border-right: none;
    }
    .plans-hero-section mdb-tab .tab-container .tab-content {
      border-left: none;
    }
    .plans-hero-section .tab-container .tab-pane ul li .nav-link {
      -moz-border-radius: 0 !important;
      -webkit-border-radius: 0 !important;
      border-radius: 0 !important;
    }
  }

  .customize-plan-wrap {
    .dollarsign {
      font-size: 15px;
      line-height: 20px;
      color: #333;
    }
    .prices {
      color: white;
      background: var(--grey-dark);
      border-radius: 5px !important;
    }
  }
  .switch label input[type="checkbox"]:checked + .lever:after {
    background-color: var(--primary-color-dark);
  }
  .switch label input[type="checkbox"]:checked + .lever {
    background-color: var(--primary-color-dark);
  }
  mdb-select > div > div.single,
  mdb-select.validate-success.ng-valid.ng-touched div.single,
  mdb-select.validate-error.ng-invalid.ng-touched div.single {
    border-bottom: none !important;
    box-shadow: none !important;
    padding-left: 15px;
    div.value {
      color: #333333;
    }
  }
  .mdb-select-toggle {
    right: 15px;
  }
  .mdb-select-toggle {
    margin-top: -0.5rem !important;
  }
  .prices .small {
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .mdb-select-toggle {
    color: var(--grey-darker);
  }

  .md-form mdb-select + label {
    padding-left: 5px;
    transform: translateY(-2px);
    transform: translateY(-34px); // to keep label at the top
    &.active {
      transform: translateY(-34px);
    }
  }

  .md-form label {
    transform: translateY(10px);
    transform: translateY(-24px) scale(0.8); // to keep label at the top
    padding-left: 5px;
    color: var(--grey-darker) !important;
    &.active {
      transform: translateY(-24px) scale(0.8);
    }
    i {
      color: #212529;
    }
  }

  .editBilling .md-form label,
  .editContact .md-form label,
  .editPayment .md-form label {
    transform: translateY(-24px) scale(0.8) !important; // to keep label at the top
    padding-left: 0px;
    color: var(--grey-darker) !important;
    &.active {
      transform: translateY(-24px) scale(0.8) !important;
      font-size: 1rem !important;
    }
    i {
      color: #212529;
    }
  }

  .switch.success-switch label {
    color: var(--grey-darker) !important;
    font-size: 13px;
    margin-bottom: 0rem;
    i {
      color: #212529 !important;
    }
  }
  .generic-term-length {
    color: var(--grey-darker) !important;
    font-weight: 300;
    font-size: 13px;
  }
  .deductible-label {
    color: var(--grey-darkest) !important;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
  }

  .md-form {
    border: 1px solid var(--grey-med);
    // padding-right: 5px;
    height: 42px;
  }
  input.form-control {
    width: calc(100% - 10px);
  }
  .customize-plan-wrap ul.stepper {
    .md-form label {
      transform: translateY(-14px) scale(0.8); // to keep label at the top
      padding-left: 5px;
      top: -18px;
    }
    .md-form label.active {
      transform: translateY(-14px) scale(0.8);
    }
  }
  mdb-select > div > div.single.focused {
    border: 1px solid var(--grey-med);
    box-shadow: 0 1px 0 0 var(--grey-med);
  }
  .mdb-select-toggle.focused {
    color: var(--grey-med);
  }

  // .md-form input[type="text"],
  // .md-form input[type="number"],
  // .md-form input[type="email"] {
  //   border: none !important;
  //   line-height: 18px;
  //   padding-left: 5px;
  //   font-size: 1.25em;
  //   padding-top: 0.5rem;
  // }
  input.form-control.validate-error.ng-invalid.ng-touched,
  input.form-control.validate-success.ng-valid.ng-touched {
    border-bottom: none !important;
    box-shadow: none !important;
    border: none;
  }
  .md-form input:focus:not([readonly]) {
    border-bottom: none !important;
    box-shadow: none !important;
  }

  .customize-plan-wrap .card {
    border-radius: 5px !important;
  }

  .step-title > span {
    position: absolute;
    left: 5px;
    font-family: "CalibreWeb-Semibold", sans-serif;
    text-transform: uppercase;
    top: 25px;
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--grey-med);
  }

  ul.stepper.horizontal {
    // overflow: visible !important;
    .step-new-content {
      overflow-x: visible !important;
      overflow-y: visible !important;
    }
  }

  @media only screen and (max-width: 420px) {
    .step-title > span {
      position: relative;
      top: 0;
      left: -8px;
    }
  }

  @include breakpoint(xs) {
    .container-margin {
      margin-top: 30px;
    }
  }

  .step.active .step-title > span {
    color: var(--primary-color-dark);
  }

  i.fa-question-circle, i.fa-info-circle {
    cursor: pointer;
  }


  .form-check-input[type="checkbox"]:checked + label:before {
    border-color: transparent var(--primary-color-dark) var(--primary-color-dark) transparent;
  }

  .justify-content-between.tabbar > a {
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: 14px;
    color: var(--grey-darker) !important;
    &.active {
      color: var(--primary-color-darkest) !important;
    }
  }

  mdb-select * {
    font-family: "CalibreWeb-Regular", sans-serif;
  }

  .verify-vehicle-header {
    padding-bottom: 10px;
    strong {
    font-weight: var(--h3-weight) !important;
    font-size: var(--h3-size);
    font-family: 'CalibreWeb-Semibold',sans-serif;
    }
  }
  .where-vin {
    top: -19px;
  }

  app-my-account-verify {
    .verify-fields {
      height: 42px;
      max-width: 330px;
      margin: auto;
    }

    .md-form label {
      transform: translateY(-24px) scale(0.8);
    }
    &.active {
      transform: translateY(-24px) scale(0.8);
    }
  }

  .modal-content.modal-dynamic form .md-form.mt-3.mb-3 {
    margin-top: 30px !important;
  }

  .customize-plan-wrap .card {
    .terms-conditions {
      position: absolute;
      top: -30px;
      color: var(--primary-color-darker);
      text-transform: uppercase;
      text-align: center;
      left: 0;
      height: 30px;
      background-color: white;
      i {
        margin-right: 10px;
      }
      a {
        text-decoration: none !important;
      }
    }
    .terms-container {
      margin-top: 10px;
      line-height: 20px;
      .small {
        font-size: 12px;
      }
    }
    .payment-container {
      margin-top: 7px;
      line-height: 20px;
      .small {
        font-size: 12px;
      }
    }
    .due-today {
      font-size: 25px;
      font-weight: bold;
    }
    .btn-back {
      background: none;
      color: var(--primary-color-darker) !important;
      box-shadow: none !important;
      border: none !important;
      &:hover {
        color: var(--primary-color-lite) !important;
      }
      &.mobile {
        color: white !important;
      }
    }
  }
  .card-body {
    padding: 16px !important;
  }
  @include breakpoint(xs) {
    .customize-plan-wrap .card {
      .prices {
        border-radius: 0 !important;
      }
    }
  }

  @include breakpoint(xs) {
    .card-body {
      padding: 32px !important;
    }
  }

  /********************
    CART PAGE
    ********************/

    .downpayment-dollarsign {
      position: absolute;
      left: 7px;
      top: 8px;
      font-size: 0.95rem;
      @include breakpoint(xs) {
        top: 8px;
      }
    }
    .downpayment-input {
      padding-left: 15px !important;
      width: calc(100% - 20px) !important;
    }

    .payment-options-tabs {
        &.card-body {
          padding: 0px !important;
        }
    }
    
    .payment-button {
      font-family: "VroomSans", sans-serif;
    }

    .shopping-cart .card-header,
    .shopping-cart hr.fade-line {
      background: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .shopping-cart {
      padding-top: 40px;
      h2 mdb-icon {
        display: none;
      }

      .md-form label {
        padding-left: 0px;
      }

      a {
        text-decoration: none;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "CalibreWeb-Bold", sans-serif;
        color: var(--color-info);

        &.click-text {
          color: var(--color-info);
        }

        @media (max-width: 900px) {
          font-size: 12px;

        }


        &.active {
          color: #041022;
        }

        mdb-icon {
          display: none;
        }
        &.desktop {
          font-size: 14px;
          color: var(--color-info);
        }
      }
      .continue-shopping {
        display: none;
      }
    }

  .container.shopping-cart .card-body {
    .semi-bold {
      font-family: "CalibreWeb-Semibold";
    }
    .card-text {
      color: #07073d;
      .align-items-baseline {
        margin-top: 5px;
      }
      .total-today-note {
        font-size: 18px;
      }
      .media-body {
        border: 1px solid #cdcdcd;
        // padding-top: 16px;
        h5 {
          letter-spacing: 1px;
          color: var(--primary-color-dark);
        }
        .col-4 {
          position: relative;
          right: 0;
        }
      }
      .row {
        .media-body {
          border: 1px solid #cdcdcd;
          // padding-top: 16px;
          h5, .cart-price {
            letter-spacing: 1px;
            color: #041022;
          }

          .col-4 {
            position: relative;
            right: 0;
          }
        }
      }
    }
  }

  .mobile-product-name {
    letter-spacing: 1px;
    color: var(--primary-color-dark);
  }

  .fa-calendar {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 21px;
    height: 21px;
    top: 5px;
    background-image: url("/assets/vroom/icons/icon-calendar.svg");

    &:before {
      content: "";
    }
  }
  app-shopping-cart {
    .fa-shopping-cart {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 70px;
      height: 70px;
      top: 0;
      background-image: url("/assets/vroom/icons/icons-cart.png");

      &:before {
        content: "";
      }
    }
  }

  /********************
    SUPPORT PAGE
    ********************/

  .support .solid-hero {
    background: none;
    padding: 100px 0 0 0;
    h2 {
      text-align: center;
      padding: 10px;
    }

    button.primary-btn {
      border: none !important;
      background-color: var(--secondary-color-med);
      i {
        color: white;
      }
    }
    button.primary-btn:hover {
      background-color: var(--secondary-color-dark);
      border: none !important;
      i {
        color: white;
      }
    }
  }

  .support .container:not(.support-items) {
    h1 {
      color: black !important;
    }
    h2 {
      padding-top: 40px;
      padding-bottom: 16px;
    }
    p,
    li {
      font-size: 18px !important;
    }
    @include breakpoint(xxs) {
      p,
      li {
        font-size: 16px !important;
      }
      &.pl-5 {
        text-align: center;
        padding-left: 0 !important;
      }
    }
  }
  .support .container.support-items > .row {
    margin-top: 0 !important;
    padding-top: 1rem !important;
    mdb-card {
      // max-width: 312px;
      margin: auto;
      max-height: 350px;
      img {
        width: 30% !important;
      }
      h2 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
      mdb-card-footer {
        padding-bottom: 15px;
        a {
          background: none;
          color: var(--primary-color-dark) !important;
          border: none;
          box-shadow: none !important;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 575px;
    i.fa-arrow-right {
      display: none;
    }

    .change-vehicle {
      text-align: center;
      font-family: "CalibreWeb-Regular", sans-serif;
      color: var(--grey-dark);
      font-size: 14px;
      width: 100%;
      a {
        font-family: "CalibreWeb-Bold", sans-serif;
        color: var(--color-info);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
    .cancel-btn {
      box-shadow: none !important;
      color: var(--primary-color-dark);
      white-space: nowrap;
    }
    .primary-btn {
      white-space: nowrap;
    }
  }
  .form-control {
    border: none !important;
    font-size: 1rem;
    padding: 8px 5px;
    margin-bottom: 8px !important;
  }
  app-modal {
    .modal-close-icon {
      top: 10px;
      left: 10px;
    }
  }

  @media (min-width: 1200px) {
    .col-xl-4 {
      flex: none;
      max-width: 100%;
    }
  }

  .btn.disabled,
  .btn:disabled {
    background-color: var(--grey-dark) !important;
    border-color: var(--grey-dark) !important;
  }

  .product-footer {
    background-color: #f9f9f9;
  }

  .product-footer-text {
    font-family: "CalibreWeb-Bold";
    font-size: 10px;
    color: var(--color-info);
    line-height: 16px;
  }

  .card-border {
    border-right: 1px solid #979797;
  }

  .overline {
    font-family: "CalibreWeb-Semibold";
    color: #6C6F75;
    font-size: 14px;
  }

  /********************
    VERIFY ACCOUNT
    ********************/

  .verify-account {
    .account-header {
      color: var(--font-black);
      font-family: "VroomSans", sans-serif;
      font-size: var(--h2-size) !important;
      font-weight: var(--h2-weight) !important;
    }
    .tab-content .col-12 {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    mdb-tabset > .container-fluid > .row {
      max-width: 430px;
      margin: auto;
    }
    mdb-card.col-md-8.card {
      max-width: 684px;
    }
    p {
      color: var(--secondary-color-med);
    }
    .container-fluid > .row > .col-md-12:first-child {
      padding: 0 3rem;
    }
    ul.md-pills {
      border: 1px solid var(--grey);
      border-radius: 50px;
      background-color: var(--grey);
    }
  }

  .verify-account .md-pills {
    li {
      padding: 5px;
    }
    .nav-link {
      font-family: "CalibreWeb-Semibold", sans-serif;
      letter-spacing: 1px;
      font-size: 16px;
      box-shadow: none !important;
      border-radius: 50px !important;
      text-transform: uppercase;
      &:hover {
        background-color: var(--grey);
      }
      &.active {
        background-color: white;
        border: 1px solid black;
        color: var(--secondary-color-med);
      }
      i.fas {
        display: none;
      }
    }
  }

  /********************
    CHECKOUT PAGE
    ********************/

    @include breakpoint(xs) {
    app-checkout {
      mdb-card-body {
        .btn {
          width: 100%;
        }
      }
    }
  }

  app-checkout {
    .checkout .edit-remove-links {
      display: none;
    }

    .coverage-start,
    .items-in-cart {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

  }

  .modal-content i.fa-shopping-cart {
    display: none;
  }

  /********************
    NAVBAR
    ********************/

  .navbar .dropdown-toggle {
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--grey-dark) !important;
    letter-spacing: 1px;
  }

  .navbar a.nav-link,
  .navbar .dropdown-toggle {
    font-family: "CalibreWeb-Semibold", sans-serif;
    font-size: 14px !important;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--grey-dark) !important;
    letter-spacing: 1px;
    cursor: pointer;
    padding-left: 6px;
    padding-right: 6px;
  }
  .navbar .nav-item.desktop.myaccount {
    padding-top: 6px;
  }
  .navbar a.nav-link:hover,
  .navbar .dropdown-toggle:hover {
    color: var(--grey-med) !important;
  }

  .navbar,
  .sideNavLeft {
    .vehicle-service-protection {
      display: none;
    }
    .how-vsp-works {
      display: none;
    }
    .myaccount.show {
      border-bottom: none;
    }
  }

  .navbar {
    .shopping-cart-icon {
      width: 20px;
      padding: 0;
      a {
        position: absolute;
        top: 0;
        right: -10px;
        width: 60px;
        display: block;
        height: 45px;
        &.waves-effect {
          overflow: visible;
        }
        mdb-icon {
          display: block;
        }
      }
    }
    .fa-shopping-cart {
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 48px;
      height: 48px;
      top: 0;
      right: 10px;
      background-image: url("/assets/vroom/icons/icons-cart.png");

      &:before {
        content: "";
      }
    }
  }

  .navbar {
    .call {
      a.phone_number.nav-link {
        color: var(--primary-color-darker) !important;
      }
    }
    .call.desktop {
      a.phone_number {
        margin-left: 20px;
        position: relative;
        padding-left: 5px !important;
        overflow: visible;
        i {
          position: absolute;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 16px;
          height: 16px;
          top: 10px;
          left: -10px;
          background-image: url(/assets/vroom/icons/icon-call.svg);
          &:before {
            content: "";
          }
        }
      }
    }
    .call.mobile {
      border: 1px solid var(--primary-color-darker);
      margin-right: 40px;
      a.phone_number {
        position: relative;
        color: var(--primary-color-darker) !important;
        span {
          margin-right: 5px;
          padding-left: 15px;
        }
        i {
          position: absolute;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 16px;
          height: 16px;
          top: 10px;
          background-image: url(/assets/vroom/icons/icon-call.svg);
          &:before {
            content: "";
          }
        }
      }
    }
  }

  .shopping-cart-icon .badge-primary {
    color: #fff;
    background-color: var(--primary-color-darker);
    position: absolute !important;
    margin-left: 15px;
    font-size: 12px;
    @include breakpoint(xs) {
      margin-left: 25px;
    }
  }

  @include breakpoint(xs) {
    .navbar {
      .shopping-cart-icon {
        position: relative;
        a {
          // right: 30px;
        }
        .badge-primary {
        }
      }
      .fa-shopping-cart {
        width: 32px;
        height: 32px;
        top: 5px;
      }
    }
  }

  @include breakpoint(xxs) {
    .navbar {
      .badge-primary {
        // left: -5px !important;
      }
    }
  }

  /********************
    MAIN LANDING PAGE
    ********************/

  .whats-not-covered {
    app-component-text {
      @include breakpoint(xs) {
        .col-12 {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .content-feature {
    &.content-feature-top-padding {
      @include breakpoint(xs) {
        padding-top: 5rem !important;
      }
    }
  }

  .vroom-main-hero {
    .content-feature-top-padding {
      max-height: 900px !important;
      // min-height: 632px !important;
      height: 632px;
    }
    @include breakpoint(xs) {
      app-component-title .col-12 {
        margin-top: -45px !important;
        max-width: 75% !important;
      }
      app-component-text .col-12 {
        margin-top: -10px !important;
        max-width: 40% !important;
        display: none;
      }
    }
  }
  @include breakpoint(xs) {
    app-component-video #media {
      width: 100% !important;
    }
    .icon-group {
      margin-bottom: 24px;
    }
  }

  // @media (width: 768px) {
  //   .content-feature-top-padding {
  //     min-width: 100%;
  //     padding: 40px 60px 60px !important;
  //   }
  //   .verify_form  {
  //     max-width: 384px;
  //     margin-left: 200px;
  //   }
  // }

  .icon-bar a i.fa-phone {
    background-image: url("/assets/vroom/icons/Vroom-icon-Phone-white.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
    top: 13px;
    right: 12px;
    &:before {
      content: "";
    }
  }
  h2.embeddedservice-chatheader_chatheader {
    font-size: 20px !important;
  }

  app-product-home {
    .main-hero-bg {
      @include breakpoint(xs) {
        background-position: 50% 40px;
      }
    }
  }

  app-product-home {
    .get-the-most-gold-vsp {
      app-component-icon-group {
        padding-bottom: 20px;
        @include breakpoint(xs) {
          padding-bottom: 0;
          .icon-group:nth-child(1) {
            margin-bottom: 42px;
          }
          .icon-group:nth-child(2) {
            margin-bottom: 0;
          }
        }
        .icon-group {
          margin-right: 20px;
        }
        img {
          position: absolute;
          left: -10px;
          top: 5px;
          @include breakpoint(xs) {
            top: 10px;
          }
        }
        .cap-head {
          text-align: left;
          padding-left: 55px;
          @include breakpoint(xs) {
            padding-left: 70px;
          }
        }
      }
    }
  }

  app-product-home .why-vsp {
    .facts .card {
      min-height: 284px;
    }
    .fact-title {
      display: none;
    }
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  app-product-home app-slice-content-block .peace-of-mind app-component-icon-group .justify-content-center {
    p {
      text-align: left;
      padding-left: 35px;
      p {
        margin-left: -35px;
      }
    }

    .icon-group {
      img {
        position: absolute;
        left: 0;
      }
    }
  }
  @include breakpoint(xs) {
    app-product-home app-slice-content-block .peace-of-mind {
      margin-bottom: 0 !important;
      app-component-icon-group .justify-content-center {
        > div:last-child {
          margin-bottom: 0px !important;
        }
        p {
          padding-left: 50px;
          p {
            margin-left: -50px;
          }
        }
      }
    }
  }

  app-component-icon-group {
    .cap-head {
      strong {
        font-weight: 900;
        strong {
          font-family: "CalibreWeb-Bold", sans-serif;
        }
      }
    }
  }

  app-product-home {
    app-slice-content-block .how-it-works .cb-text {
      .title-component .col-12{
        padding-bottom: 0px !important;
      }
      .pb-2 > div > p {
        display: block;
        line-height: 18px !important;
        // min-height: 30px; //Removed for D2CD-688
      }
      app-disclaimer {
        .pl-4 {
          padding-left: 0 !important;
          .col-12 {
            padding-left: 0 !important;
          }
        }
        .disclaimer {
          color: var(--grey-dark) !important;
          .superscript {
            margin-left: -15px;
          }
        }
      }
    }
  }

  app-product-home {
    app-slice-content-block .why-vsp {
      .card {
        min-height: 250px !important;
      }
    }
    app-slice-content-block .whats-covered {
      .content-feature:nth-child(2) {
        app-component-text {
          .col-12 {
            padding-top: 43px !important;
            @include breakpoint(xs) {
              padding-top: 65px !important;
            }
          }
        }
      }
      .content-feature:nth-child(3) {
        background-color: rgba(255, 255, 255, 0) !important;
        padding-top: 150px !important;
      }
    }
    .whats-covered .feature-component {
      app-component-text {
        ul {
          padding-left: 18px;
          list-style-image: url("/assets/vroom/icons/Vroom-icon-check.svg");
        }
      }
    }
    app-slice-content-block .whats-covered .content-feature:nth-child(1) {
      min-width: 50%;
      max-width: 50%;
      padding-right: 0 !important;
    }

    app-slice-content-block .whats-covered .content-feature:nth-child(2) {
      app-component-title .col-12 {
        position: absolute;
        min-width: 350px;
        @include breakpoint(xs) {
        padding-top: 24px !important;
        }
      }
      .feature-component:nth-child(2) .cb-text {
        margin-top: 40px;
        @include breakpoint(xs) {
          margin-top: 65px;
        }
      }
    }
  }

  @include breakpoint(xs) {
    app-product-home {
      app-slice-content-block .whats-covered {
        .content-feature:nth-child(3) {
          padding-top: 73px !important;
        }
      }
      app-slice-content-block .whats-covered.content-block-wrap {
        background-color: rgb(245, 245, 245);
      }
      app-slice-content-block .whats-covered .content-feature:nth-child(1) {
        min-width: 100%;
        max-width: 100%;
        padding-right: 0 !important;
      }
    }
    app-product-home {
      app-slice-content-block .worry-free-protection {
        h4 {
          margin-top: 16px;
          text-transform: uppercase;
        }
        h2 {
          margin-bottom: 32px;
        }
        p {
          margin-bottom: 32px;
        }
      }
    }
  }

  .content-block-wrap.peace-of-mind {
    @include breakpoint(xs) {
      .content-feature .mobile-margin {
        padding-top: 0 !important;
      }
    }
  }


  .content-block-wrap.whats-covered {
    .cb-text.mobile-margin {
      padding-top: 0px !important;
    }
    .secondary-btn {
      background:rgba(0, 0, 0, 0) !important;
      border: none !important;
      box-shadow: none !important;
      font-family: "CalibreWeb-Bold", sans-serif;
      color: var(--color-info) !important;
      text-transform: uppercase;
      font-size: 14px !important;
      letter-spacing: 1px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .confirmation {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--grey-darkest);
    }
  }

  app-footer-questions {
    .row {
      @media (max-width: 769px) {
        padding: 0 32px;
        .subfooter-item {
          padding: 12px 0;
        }
      }
    }
  }

  /****************
    * Customize Plan
    *****************/

  @media only screen and (min-width: 769px) {
    .my-pricing-background {
      .container {
        h1 {
          max-width: 75%;
        }
      }
    }
  }

  .price-cost {
    font-size: 36px;
    color: var(--font-black);
    font-family: "VroomSans", sans-serif;
    line-height: 36px;
    .price-cost-asterisk {
      font-family: "CalibreWeb-Regular", sans-serif;
      font-size: 20px !important;
      position: relative;
      left: 3px;
    }
  }

  .plan-select-border {
    border-top: 3px solid var(--font-black);
  }

  .plan-select {
    text-transform: uppercase;
    color: var(--grey-lite) !important;
    background-color: #E71519;
    font-weight: normal;
    letter-spacing: 1px;
    font-family: "CalibreWeb-Semibold", sans-serif;
    &.active {
      position: relative;
      font-family: "CalibreWeb-Bold", sans-serif;
      border-bottom: var(--font-black);
      background-color: var(--font-black);
    }
  }
  .cr-red {
    background-color: var(--primary-color-darker) !important;
  }

  .customize-container {
    .md-form {
      background-color: white;
    }
  }

  .parts-covered-background {
    .parts {
      .fa-check {
        color: var(--primary-color-dark);
      }
    }
  }

  .select-container {
    .mobile {
      // background: var(--grey-lite);
    }
  }

  .customize-container,
  .coverage-overview {
    background: var(--grey-lite);
  }

  .mobile-plan-footer {
    background-color: var(--secondary-color-med) !important;
      .plan-name {
        font-size: 20px;
        font-weight: bold;
        font-family: "CalibreWeb-Semibold", sans-serif;
      }
      .due-today span {
        font-family: "CalibreWeb-Semibold", sans-serif;
      }
  }


  /********************
    VSC LANDING PAGE
  ********************/
  @include breakpoint(xs) {
    .whats-covered, .how-it-works {
      .full-img-container {
        padding: 0 !important;
      }
    }
  }

  @media only screen and (min-width: 641px) and (max-width: 1007px) {
    h1 {
      font-size: 42px !important;
    }
    h2 {
      font-size: 32px !important;
    }
    app-product-home .content-block-wrap {
      // max-height: 632px !important; //Removed for D2CD-688
    }
  }
}


@media only screen and (max-width: 641px) {
  app-component-title .h1 {
    text-align: center;
  }
}

app-checkout-registration-info-state-change-modal {
  .modal-body {
    padding: 32px !important;
  }
  .modal-close-icon {
      left: 12px !important;
  }
  .primary-btn {
    width: 240px !important;
  }

  @include breakpoint(xs) {
    .primary-btn {
      width: 190px !important;
    }
    .modal-body {
      padding: 16px !important;
    }
  }
}

.cookie-acceptance-modal {
  background-color: var(--grey-darkest);
}

.prismic-grid {
  .full-img-container {
    @include breakpoint(xs) {
    padding: 0 !important
    }
  }
}

.cta-call-us {
  h4 {
    strong {
      font-family: "CalibreWeb-Bold", sans-serif;
    }
  }
}

.content-feature.countdown-timer {
  padding-top: 0 !important;
}